import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';
import moment from 'moment';

import AttendeePolling from './AttendeePolling';
import WaitingScreen from './WaitingScreen';
import { usePresenterOrAttendee } from '../../context/AttendeeAndPresenterProvider';
import WelcomePage from './WelcomePage';
import LoadingComponent from '../../common/LoadingComponent';
import styles from '../../styles/AttendeeStyles.module.scss';
// import AttendeeThankYou from './AttendeeThankYou';

const AttendeeController = () => {
  const [loading, setLoading] = useState(true);
  const {
    isSessionStarted,
    activeSession,
    isSessionComplete,
    currentPollingStatus,
    customClientDomain,
    sessionID,
    attendeeStyles
  } = usePresenterOrAttendee();

  useEffect(() => {
    if (!localStorage.getItem('UNIQUEID')) {
      localStorage.setItem('UNIQUEID', uuidv4());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (activeSession && attendeeStyles) {
      // When styles are set start
      setLoading(false);
    }
  }, [activeSession, attendeeStyles]);

  const renderLocationandDateTime = () => {
    if (!isSessionStarted) {
      const momentDate = moment(activeSession?.date);
      return (
        <>
          {activeSession?.location}
          <br />
          {momentDate.utc().format('ll')}
          <br />
          {momentDate.utc().format('LT')}
        </>
      );
    }
  };

  const renderAttendeePage = () => {
    if (isSessionComplete || activeSession.isEvaluationOnly || activeSession.isSessionEvalOnly) {
      const attendeeId = localStorage.getItem('UNIQUEID') || '';
      const evalLink = customClientDomain ? `//${customClientDomain}/eval` : '/eval';
      // window.open(`${evalLink}/${sessionID}/${attendeeId}`)
      window.location.href = `${evalLink}/${sessionID}/${attendeeId}`;
      return;
    }

    if (!isSessionStarted) {
      return (
        <WelcomePage
          attendeeStyles={{
            ...attendeeStyles.welcomeStyles,
            ...attendeeStyles.deckConfig,
            ...attendeeStyles.universalStyles
          }}
        />
      );
    }
    if (currentPollingStatus === 2) {
      return (
        <AttendeePolling
          attendeeStyles={{
            ...attendeeStyles.pollingStyles,
            ...attendeeStyles.deckConfig,
            ...attendeeStyles.universalStyles
          }}
        />
      );
    }
    return (
      <WaitingScreen
        attendeeStyles={{
          ...attendeeStyles.waitingStyles,
          ...attendeeStyles.deckConfig,
          ...attendeeStyles.universalStyles
        }}
      />
    );
  };

  if (loading) return <LoadingComponent />;
  return (
    <div
      className={styles.eventsContainer}
      style={{ '--background-color': attendeeStyles.controllerStyles.background }}
    >
      <div className={styles.presenterHeader}>
        <div
          style={{
            color: attendeeStyles.controllerStyles.presenterColor,
            fontWeight: 'bold'
          }}
        >
          Presented by: {activeSession?.presenter}
          <span
            style={{
              display: 'block',
              height: 2,
              width: 120,
              margin: '8px auto',
              background: attendeeStyles.background
            }}
          />
          {renderLocationandDateTime()}
        </div>
      </div>
      {renderAttendeePage()}
      {/* <div className={styles.audienceFooter}>
        <img alt="logo" src={attendeeStyles.tertiaryLogo} />
      </div> */}
    </div>
  );
};

export default AttendeeController;
