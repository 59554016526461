import React, { useState } from 'react';
import _ from 'lodash';
import { PlayCircleFilledWhiteOutlined, PauseCircleOutlineOutlined } from '@mui/icons-material';

import LoadingComponent from '../../common/LoadingComponent';
import BeginSessionPage from './BeginSessionPage';
import ControllerSlide from './ControllerSlide';
import ControllerSidebar from './controllerSidebar/ControllerSidebar';
import { getSlideByID, filterOnlyPollingSlides } from '../../utils/utils';
import config from '../../config';
import { usePresenterOrAttendee } from '../../context/AttendeeAndPresenterProvider';

const { sessionStatus } = config;

const PresenterController = () => {
  const {
    activeSession,
    isSessionStarted,
    sessionID,
    slidesConfig,
    setCurrentSlide,
    socket,
    currentSlide,
    currentPollingStatus,
    isSessionComplete,
    setIsVideoPlaying,
    isForwardBackActive
  } = usePresenterOrAttendee();

  const toggleVideoPlaying = (state) => {
    console.log('TOGGLE');
    setIsVideoPlaying(state);

    socket.emit('action', {
      actionType: 'togglePlayingState',
      play: state,
      room: `${activeSession.id}|video`
    });
  };

  const skipInVideo = (state) => {
    if (isForwardBackActive) {
      socket.emit('action', {
        actionType: 'timeSkip',
        time: 10,
        forward: state,
        room: `${activeSession.id}|video`
      });
    }
  };

  const startProgramClick = () => {
    // get the ids of the slide config to construct the pollingSessionStatus table
    const pollingConfigs = filterOnlyPollingSlides(slidesConfig);
    console.log('PollingConfig', pollingConfigs);
    socket.emit('updateSession', {
      sessionID: activeSession.id,
      status: 2,
      beginSession: true,
      pollingConfigs,
      room: `${activeSession.id}|attendee`
    });
  };

  const videoNavigationHandler = (item) => {
    // go to target time tell socket
    console.log('Go to slide', item);
    item.room = `${sessionID}|video`;
    item.actionType = 'seekToTargetTime';
    item.sessionID = sessionID;

    let startOrEndTime = item.sideBarClickType === 'start' ? item.startTime : item.endTime;
    if (
      !item.endTime &&
      item.hotSpotTime &&
      item.hotSpotTime > 0 &&
      item.sideBarClickType !== 'start'
    ) {
      startOrEndTime = item.hotSpotTime + (item.stopDelay || 0);
    }

    if (item.sideBarClickType) {
      item.autoPlay = item.sideBarClickType === 'start';
      item.startTime = startOrEndTime;
    }

    socket.emit('action', item);

    // If polling is open, CLOSE
    if (currentPollingStatus === 2 && !item.isChapter) {
      socket.emit('updatePollingState', {
        sessionID,
        slideConfigID: currentSlide.id,
        room: `${sessionID}|attendee`,
        newPollingStatus: 3
      });
    }

    // If we are changing the slideID
    if (item.isChapter && item.id !== currentSlide.id) {
      setCurrentSlide(getSlideByID(slidesConfig, item.id));
    }
  };

  if (!activeSession) return <LoadingComponent />;
  // Waiting to start

  if (isSessionComplete) {
    return <div className="flex-center">This Session has been closed</div>;
  }

  if (activeSession.status === sessionStatus.deletedStatus) {
    return <div className="flex-center">This Session has been deleted</div>;
  }

  if (!isSessionStarted) {
    return <BeginSessionPage startProgramClick={startProgramClick} />;
  }

  return (
    <div style={{ postion: 'relative', height: '100%' }}>
      <ControllerSidebar
        style={{ position: 'absolute', zIndex: '100' }}
        videoNavigationHandler={videoNavigationHandler}
      />
      <ControllerSlide
        videoNavigationHandler={videoNavigationHandler}
        toggleVideoPlaying={toggleVideoPlaying}
        skipInVideo={skipInVideo}
      />
    </div>
  );
};

export default PresenterController;
