import React from 'react';
import styles from '../../styles/AttendeeStyles.module.scss';
import { usePresenterOrAttendee } from '../../context/AttendeeAndPresenterProvider';

const WaitingScreen = ({ attendeeStyles }) => {
  return (
    <div
      className={`${styles.centeredScreen} flex-center direction-column`}
      style={{ '--header-color': attendeeStyles.headerColor }}
    >
      <div className={styles.audienceHeaderLogo}>
        <img alt="" src={attendeeStyles.logo} />
      </div>
      <h1>
        Please stand by. <br /> We will <br /> continue shortly.
      </h1>
    </div>
  );
};

export default WaitingScreen;
