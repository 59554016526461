import React, { useState } from 'react';
import { Button, Stack } from '@mui/material';
import _ from 'lodash';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {
  ArrowForwardIos,
  ArrowBackIos,
  PauseCircleOutlineOutlined,
  PlayCircleFilledWhiteOutlined,
  Forward10,
  Replay10
} from '@mui/icons-material';

import Sidebar from './pollingSidebar/Sidebar';
import ViewModalButton from '../../common/ViewModalButton';
import { getSlideByID } from '../../utils/utils';
import { usePresenterOrAttendee } from '../../context/AttendeeAndPresenterProvider';

import styles from '../../styles/PresenterContoller.module.scss';

const ControllerSlide = ({ videoNavigationHandler, toggleVideoPlaying, skipInVideo }) => {
  const {
    currentSlide,
    sessionID,
    slidesConfig,
    pollingEnabled,
    isVideoPlaying,
    isForwardBackActive
  } = usePresenterOrAttendee();

  const [sideBarOpen, setSideBarOpen] = useState(true);

  const renderBottomNavigationButtons = () => {
    const navButtons = currentSlide.bottomNavigation?.map((content, key) => {
      const bottomNavSlide = getSlideByID(slidesConfig, content.relatedSlideId);
      bottomNavSlide.autoPlay = true;

      const leftArrow = content.direction === 'left';

      return (
        <div className={styles['controller-slide']} key={key}>
          <Button
            variant={'contained'}
            style={{ width: '100%' }}
            onClick={() => videoNavigationHandler(bottomNavSlide)}
          >
            {leftArrow && <ArrowBackIos />}
            {bottomNavSlide.title}
            {!leftArrow && <ArrowForwardIos />}
          </Button>
          <p>{bottomNavSlide.header}</p>
        </div>
      );
    });
    return navButtons;
  };

  const forwardBackButtonStyle = isForwardBackActive
    ? { cursor: 'pointer', color: '#1976d2' }
    : { cursor: 'default', color: '#808080' };

  return (
    <div className="flex" style={{ height: '100%' }}>
      <div className={styles['presenter-controller-body']}>
        <div className={styles['play-and-pause-buttons']}>
          <Replay10
            fontSize="large"
            onClick={() => skipInVideo(false)}
            style={forwardBackButtonStyle}
          />
          <PauseCircleOutlineOutlined
            fontSize="large"
            onClick={() => toggleVideoPlaying(false)}
            style={{ cursor: 'pointer', color: isVideoPlaying ? 'black' : '#1976d2' }}
          />

          <PlayCircleFilledWhiteOutlined
            fontSize="large"
            onClick={() => toggleVideoPlaying(true)}
            style={{ cursor: 'pointer', color: isVideoPlaying ? '#1976d2' : 'black' }}
          />
          <Forward10
            fontSize="large"
            onClick={() => skipInVideo(true)}
            style={forwardBackButtonStyle}
          />
        </div>

        <div className={styles['presenter-innerbody-topbox']}>
          {!sideBarOpen && (
            <OpenInNewIcon
              className={styles['sidebar-open-icon']}
              sx={{ '&:hover': { color: 'blue', cursor: 'pointer' } }}
              onClick={() => {
                setSideBarOpen(true);
              }}
            />
          )}

          <div className={styles['presenter-innerbody-header']}>
            <p>{currentSlide.title}</p>
            <p>{currentSlide.header}</p>
          </div>
          <div>
            <p style={{ textAlign: 'center' }}>{currentSlide.subHeader}</p>
            <Stack direction="row" justifyContent={'space-between'}>
              {currentSlide.showNextButtonOnly ? (
                <Button
                  variant={'outlined'}
                  style={{ width: '50%', margin: 'auto' }}
                  onClick={() => {
                    if (currentSlide.nextButtonOverride) {
                      const continueButtonSlide = getSlideByID(
                        slidesConfig,
                        currentSlide.nextButtonOverrideId
                      );
                      videoNavigationHandler(continueButtonSlide);
                    } else {
                      toggleVideoPlaying(true);
                    }
                  }}
                >
                  Continue
                </Button>
              ) : (
                currentSlide.content.map((item, key) => (
                  <div
                    key={key}
                    style={{ width: `${(0.98 / currentSlide.content.length) * 100}%` }}
                  >
                    {/* MATH HERE: less than 1 to leave space divided by total to get size of each bitton */}
                    <Button
                      variant={'outlined'}
                      style={{ width: '100%' }}
                      onClick={() => {
                        videoNavigationHandler(item);
                      }}
                    >
                      {item.text}
                    </Button>
                    <p>{item.caption}</p>
                  </div>
                ))
              )}
            </Stack>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: currentSlide.bottomNavigation.length > 1 ? 'space-between' : 'center'
          }}
        >
          {renderBottomNavigationButtons()}
        </div>
        <div className={styles['presenter-controller-footer']}>
          <ViewModalButton endProgram>
            <Button variant="outlined">End Program</Button>
          </ViewModalButton>
        </div>
      </div>
      {pollingEnabled && sideBarOpen && (
        <Sidebar setSideBarOpen={setSideBarOpen} slide={currentSlide} sessionID={sessionID} />
      )}
    </div>
  );
};

export default ControllerSlide;
