import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { Popover, Button } from '@mui/material';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

import CreateEditSessionModal from '../../../common/modals/CreateEditSessionModal';
import { getSessions, getClients, setData } from '../../../redux/dataSlice';
import LoadingComponent from '../../../common/LoadingComponent';
import SessionsTable from './SessionTable';
import MobileSessionList from './MobileSessionList';
// import DeckSelectDropdown from '../../../common/DeckSelectDropdown';

import config from '../../../config';

const { sessionStatus } = config;

const appleIcon = require('../../../assets/apple.png');
const windowsIcon = require('../../../assets/windows.png');

const Home = () => {
  const [sessionModalOpen, setSessionModalOpen] = useState(false);
  const [client, setClient] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedOS, setSelectedOS] = useState(null);

  const { loading, sessions, clients, selectedClient } = useSelector((state) => state.data);

  const buttonStyle = {
    border: '1px solid gray',
    borderRadius: 5,
    height: 50,
    width: 50,
    fontWeight: 'bold',
    cursor: 'pointer'
  };

  const dispatch = useDispatch();

  useEffect(() => {
    // console.log('in UseEffect Home... getting sessions');

    dispatch(getClients());
    dispatch(getSessions());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (clients && selectedClient && clients[selectedClient]) {
      setClient(clients[selectedClient]);
    }
  }, [clients, selectedClient]);

  const handleAppDownloadClick = (selectedAppType) => {
    return (e) => {
      const { clientDeck } = client;
      if (_.isEmpty(clientDeck)) return;

      if (clientDeck.length > 1) {
        setSelectedOS(selectedAppType);
        setAnchorEl(e.currentTarget);
        return;
      }
      window.open(clientDeck[0][selectedAppType], '_blank');
    };
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setSelectedOS(null);
  };

  const renderPopOver = () => {
    if (_.isEmpty(client) || (client && client.clientDeck?.length < 2)) return;
    const popOverOpen = Boolean(anchorEl);
    return (
      <Popover
        id={popOverOpen ? 'app-download-popover' : undefined}
        open={popOverOpen}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        disableScrollLock
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center'
        }}
      >
        <div
          className="flex-center direction-column"
          style={{ justifyContent: 'space-between', padding: 10 }}
        >
          {client.clientDeck.map((media) => {
            return (
              <Button
                key={media.name}
                onClick={() => {
                  window.open(media[selectedOS], '_blank');
                  handlePopoverClose();
                }}
                variant="outlined"
                sx={{ width: 200, margin: 1 }}
                endIcon={<FileDownloadOutlinedIcon />}
              >
                {media.name}
              </Button>
            );
          })}
        </div>
      </Popover>
    );
  };

  const renderVideoDownloadComponent = () => {
    if (isMobile || client.isEvaluationOnly) return;
    return (
      <div style={{ width: '100%' }}>
        <div className="home-download">
          <div>
            <p style={{ marginTop: 0 }}>Download a compatible presentation file: &nbsp;</p>
            <div className="flex-center" style={{ gap: '1vw' }}>
              <div
                className="flex-center"
                style={buttonStyle}
                onClick={handleAppDownloadClick('winApp')}
                role="button"
                tabIndex={0}
              >
                <img alt="Windows" src={windowsIcon} height={25} />
                &nbsp;Windows
              </div>
              <div
                className="flex-center"
                style={buttonStyle}
                onClick={handleAppDownloadClick('macApp')}
                role="button"
                tabIndex={0}
              >
                <img alt="Mac" src={appleIcon} height={25} />
                &nbsp;Mac
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const toggleSessionModal = () => {
    setSessionModalOpen(!sessionModalOpen);
  };

  if (loading) return <LoadingComponent />;
  if (!client) return <></>;

  console.log('client', client);
  return (
    <div className="home-wrapper">
      <CreateEditSessionModal
        handleClose={toggleSessionModal}
        open={sessionModalOpen}
        client={client}
        isCreateSession
      />
      {renderPopOver()}
      <div className="home-header-nav">
        <p>New Session</p>
        <Button onClick={toggleSessionModal} id="NewSession" variant={'contained'}>
          Create +
        </Button>
      </div>

      <div className="home-body-wrapper">
        {renderVideoDownloadComponent()}
        {isMobile ? (
          <div>
            <p className="home-body-header">Sessions</p>
            <MobileSessionList
              isEvaluationOnly={client.isEvaluationOnly}
              sessions={sessions.filter((session) => {
                return (
                  session.client === selectedClient &&
                  ![sessionStatus.closedStatus, sessionStatus.deletedStatus].includes(
                    session.status
                  )
                );
              })}
              type={'openSessions'}
            />
            <p className="home-body-header">Closed Sessions</p>
            <MobileSessionList
              isEvaluationOnly={client.isEvaluationOnly}
              sessions={sessions.filter((session) => {
                return (
                  session.client === selectedClient && session.status === sessionStatus.closedStatus
                );
              })}
              type={'closedSessions'}
            />
          </div>
        ) : (
          <div>
            <p className="home-body-header">Sessions</p>
            <SessionsTable
              isEvaluationOnly={client.isEvaluationOnly}
              sessions={sessions.filter((session) => {
                return (
                  session.client === selectedClient &&
                  ![sessionStatus.closedStatus, sessionStatus.deletedStatus].includes(
                    session.status
                  )
                );
              })}
            />
            <p className="home-body-header">Closed Sessions</p>
            <SessionsTable
              isEvaluationOnly={client.isEvaluationOnly}
              sessions={sessions.filter((session) => {
                return (
                  session.client === selectedClient && session.status === sessionStatus.closedStatus
                );
              })}
              closedSessions
            />
          </div>
        )}
      </div>
      <div className="home-footer">
        {client?.attendeeStyles?.dashboardPage?.jobCode || '08/24 FA-11235281'}
      </div>
    </div>
  );
};

export default Home;
